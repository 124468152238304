<template>
  <section class="ds s-pt-50 s-pb-20 s-pt-xl-60 s-pb-xl-40 c-gutter-60 c-mb-30">
    <div class="container">
      <div class="col-12" v-if="category == 'tarot-shuffle'">
        <div
          class="vertical-item text-center service-layout-1 box-shadow content-padding"
        >
          <div class="item-media">
            <img
              src="@/images/category/tarot-shuffle.jpg"
              alt="img"
              @click="$router.push({ name: 'Tarot Shuffle Form' })"
            />
          </div>
          <div class="item-content" id="content">
            <p class="mt-0 links-maincolor2" id="head">
              တစ်နေ့တာ တားရော့ကံကြမ္မာ
            </p>
            <!-- implement this from json to html -->
            <p id="desc" v-html="getCategoryData.tarot_shuffle.desc_template">
            </p>

            <span
              id="btn-oneyear"
              @click="$router.push({ name: 'Tarot Shuffle Form' })"
            >
              မေးမည်
            </span>
          </div>
        </div>
      </div>

      <!-- col- -->

      <div class="col-12" v-if="category == 'compatability-love'">
        <div
          class="vertical-item text-center service-layout-1 box-shadow content-padding"
        >
          <div class="item-media">
            <img
              src="@/images/category/compatability-love.jpg"
              alt="img"
              @click="$router.push({ name: 'Compatability Love Form' })"
            />
          </div>
          <div class="item-content" id="content">
            <p class="mt-0 links-maincolor2" id="head">ဖူးစာဖက်</p>
            <p id="desc" v-html="getCategoryData.compatability_love.desc_template">
              
            </p>

            <span
              id="btn-oneyear"
              @click="$router.push({ name: 'Compatability Love Form' })"
            >
              မေးမည်
            </span>
          </div>
        </div>
      </div>

      <!-- col- -->

      <div class="row">
        <!-- <div class="col-12" v-if="category == 'oneyear'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/oneyear.png"
                alt="img"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 10 } })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">တစ်နှစ်စာဟောစာတမ်း</p>
              <p id="desc">
                တစ်နှစ်စာအထူးဟောစာတမ်းအတွက် ဉာဏ်ပူဇော်ခ ၄၅၀၀ ကျပ်ကျသင့်ပါမည်။
              </p>

              <span
                id="btn-oneyear"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 10 } })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div> -->

        <!-- col- -->
        <div class="col-12" v-if="category == 'tarot'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/tarot.jpg"
                alt="img"
                @click="
                  $router.push({
                    name: 'tarot',
                    params: { categoryName: 'tarot' },
                  })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">တားရော့ဗေဒင်</p>
              <p id="desc" v-html="getCategoryData.tarot.desc_template">
              </p>

              <span
                id="btn-oneyear"
                @click="
                  $router.push({
                    name: 'tarot',
                    params: { categoryName: 'tarot' },
                  })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div>

        <!-- .col-* -->
        <div class="col-12" v-if="category == 'clairvoyance'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/clairvoyance.png"
                alt="img"
                @click="
                  $router.push({
                    name: 'star',
                    params: { categoryName: 'clairvoyance' },
                  })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">အကြားအမြင်ဟောစာတမ်း</p>
              <p id="desc" v-html="getCategoryData.clairvoyance.desc_template">
                
              </p>
              <span
                id="btn-clairvoyance"
                @click="
                  $router.push({
                    name: 'clairvoyance',
                    params: { categoryName: 'clairvoyance' },
                  })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'free'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/free.png"
                alt="img"
                @click="$router.push({ name: 'free' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">
                အခမဲ့ (နေ့စဉ်၊အပတ်စဉ်၊လစဉ်)
              </p>
              <p id="desc">
                အချစ်ဗေဒင်၊ အကျိုးပေးဂဏန်း၊ လစဉ်အဟောများကို အခမဲ့ရယူနိုင်ပါသည်။
              </p>
              <span id="btn-free" @click="$router.push({ name: 'free' })"
                >မေးမည်</span
              >
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'chiromancy'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/chiromancy.png"
                alt="img"
                @click="
                  $router.push({
                    name: 'star',
                    params: { categoryName: 'chiromancy' },
                  })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">လက္ခဏာဗေဒင်</p>
              <p id="desc" v-html="getCategoryData.chiromancy.desc_template">
               
              </p>

              <span
                id="btn-chiromancy"
                @click="
                  $router.push({
                    name: 'star',
                    params: { categoryName: 'chiromancy' },
                  })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'child'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/child.png"
                alt="img"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 11 } })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">ကလေးအမည်ပေး</p>
              <p id="desc" v-html="getCategoryData.child.desc_template">
                
              </p>

              <span
                id="btn-child"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 11 } })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'love'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/love.png"
                alt="img"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 275 } })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">အချစ်ရေးအဟော</p>
              <p id="desc" v-html="getCategoryData.love.desc_template">
                
              </p>

              <span
                id="btn-love"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 275 } })
                "
              >
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'phone'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/phone.png"
                alt="img"
                @click="$router.push({ name: 'phone' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">
                ဖုန်းဖြင့်တိုက်ရိုက်ဗေဒင်မေးမည်
              </p>
              <p id="desc" v-html="getCategoryData.phone.desc_template">
                
              </p>

              <span id="btn-phone" @click="$router.push({ name: 'phone' })">
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'direct'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/direct.png"
                alt="img"
                @click="$router.push({ name: 'direct' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">
                ဗေဒင်ဆရာနှင့်တိုက်ရိုက်မေးမည်
              </p>
              <p id="desc">
                အချစ်ရေး၊အိမ်ထောင်ရေး၊ကျန်းမာရေး၊ကိုယ်ပိုင်စီးပွားရေး၊အလုပ်အကိုင်၊ပညာရေး၊ငွေရေးကြေးရေး၊ထီပေါက်ကိန်း၊လူမှုရေး၊
                အထွေထွေ အဟောများ မေးမြန်းနိုင်ပါသည်။
              </p>

              <span id="btn-direct" @click="$router.push({ name: 'direct' })">
                မေးမည်
              </span>
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <!-- <div class="col-12" v-if="category == 'business'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/business.png"
                alt="img"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 267 } })
                "
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">လုပ်ငန်းအမည်ပေး</p>
              <p id="desc">
                လုပ်ငန်းအမည်ပေးအတွက် ဉာဏ်ပူဇော်ခ ၄၅၀၀ ကျပ်ကျသင့်ပါမည်။
              </p>

              <span
                id="btn-business"
                @click="
                  $router.push({ name: 'special', params: { categoryId: 267 } })
                "
                >မေးမည်</span
              >
            </div>
          </div>
        </div> -->
        <!-- .col-* -->

        <div class="col-12" v-if="category == 'orders'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/orders.png"
                alt="img"
                @click="$router.push({ name: 'orderbyCategory' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">အဖြေများ</p>
              <p id="desc">သင် ၃လအတွင်းမေးထားခဲ့ဖူးသောဟောစာတမ်းများ</p>
              <!-- နည်းပညာအခက်အခဲကြောင့် ဝန်ဆောင်မှု ယာယီရပ်ဆိုင်း၍
              ပြုပြင်နေပါသည်။<br />အဆင်မပြေမှုများအတွက်တောင်ပန်အပ်ပါသည်။ -->

              <span
                id="btn-orders"
                @click="$router.push({ name: 'orderbyCategory' })"
                >ကြည့်မည်</span
              >
              <!-- Hello test -->

              <!-- <div class="item-media">
              <img
                src="@/images/category/orders.png"
                alt="img"
                @click="$router.push({ name: 'orders' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">အဖြေများ</p>
              <p id="desc">သင် ၃လအတွင်းမေးထားခဲ့ဖူးသောဟောစာတမ်းများ</p>

              <span id="btn-orders" @click="$router.push({ name: 'orders' })"
                >ကြည့်မည်</span
              > -->
            </div>
          </div>
        </div>
        <!-- .col-* -->
        <div class="col-12" v-if="category == 'zoom'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/zoom.png"
                alt="img"
                @click="$router.push({ name: 'zoom' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">
                Video Call ဖြင့်ဗေဒင်မေးမည်
              </p>
              <p id="desc">
                Video Call တိုက်ရိုက်မေးမြန်းမှုအတွက် (၁) ရက်ကြိုတင်၍ Booking
                လုပ်ပေးပါရန်။
              </p>

              <span id="btn-zoom" @click="$router.push({ name: 'baydinsayar' })"
                >မေးမည်</span
              >
            </div>
          </div>
        </div>
        <!-- .col-* -->

        <div class="col-12" v-if="category == 'newyearbaydin'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/newyear-2024.jpg"
                alt="img"
                @click="$router.push({ name: 'NewYearBaydin' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">
                တစ်နှစ်စာ ဟောစာတမ်း
              </p>
              <p id="desc" v-html="getCategoryData.newyearbaydin.desc_template">
                
              </p>

              <span
                id="btn-business"
                @click="$router.push({ name: 'NewYearBaydin' })"
                >မေးမည်</span
              >
            </div>
          </div>
        </div>
        <!-- .col-* -->

        <div class="col-12" v-if="category == 'nawin'">
          <div
            class="vertical-item text-center service-layout-1 box-shadow content-padding"
          >
            <div class="item-media">
              <img
                src="@/images/category/nawin.jpg"
                alt="img"
                @click="$router.push({ name: 'Nawin' })"
              />
            </div>
            <div class="item-content" id="content">
              <p class="mt-0 links-maincolor2" id="head">နဝင်းယတြာ</p>
              <p id="desc">
                နဝင်းယတြာ ပြုလုပ်ရန်အတွက် ဉာဏ်ပူဇော်ခ ၁၈၀၀၀ ကျပ် ကျသင့်ပါမည်။
              </p>
              <!-- <p class="mt-0 links-maincolor2" id="head">Testing</p>
              <p id="desc">Testing Service</p> -->

              <span id="btn-business" @click="$router.push({ name: 'Nawin' })"
                >မေးမည်</span
              >
            </div>
          </div>
        </div>
        <!-- .col-* -->
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.common.js';

export default {
  name: `Category`,
  data() {
    return {
      category: this.$route.params.categoryName,
    };
  },
  methods: {},
  computed: {
    ...mapGetters(['getCategoryData'])
  }
};
</script>

<style scoped>
#content {
  padding: 15px;
}
#desc {
  font-weight: normal;
}

#head {
  font-size: 18px;
}

#btn-oneyear {
  background-color: #46892f;
  border-color: #46892f;
  padding: 8px 16px;
  display: inline-block;
}
#btn-clairvoyance {
  background-color: #ff8f00;
  border-color: #ff8f00;
  padding: 8px 16px;
  display: inline-block;
}
#btn-free {
  background-color: #fe61db;
  border-color: #fe61db;
  padding: 8px 16px;
  display: inline-block;
}
#btn-chiromancy {
  background-color: #925fff;
  border-color: #925fff;
  padding: 8px 16px;
  display: inline-block;
}
#btn-child {
  background-color: #767676;
  border-color: #767676;
  padding: 8px 16px;
  display: inline-block;
}
#btn-love {
  background-color: #ff4f4f;
  border-color: #ff4f4f;
  padding: 8px 16px;
  display: inline-block;
}
#btn-phone {
  background-color: #f49893;
  border-color: #f49893;
  padding: 8px 16px;
  display: inline-block;
}
#btn-direct,
#btn-zoom {
  background-color: #6bb2ff;
  border-color: #6bb2ff;
  padding: 12px 30px;
  display: inline-block;
}
#btn-business {
  background-color: #ff4f4f;
  border-color: #ff4f4f;
  padding: 12px 30px;
  display: inline-block;
}
#btn-orders {
  background-color: #d117c5;
  border-color: #d117c5;
  padding: 12px 30px;
  display: inline-block;
}
</style>
