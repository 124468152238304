var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"s-py-45 s-py-xl-75 c-gutter-60"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('main',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row"},_vm._l((_vm.baydinsayar),function(item){return _c('div',{key:item.name,staticClass:"col-12 col-md-4 my-3"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":item.image,"alt":item.name},on:{"click":function($event){return _vm.$router.push({
                    name: 'zoom',
                    params: { baydinsayar: item.name },
                  })}}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"card-footer"},[_c('center',[_c('span',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        name: 'zoom',
                        params: { baydinsayar: item.name },
                      })}}},[_vm._v(" မေးမည် ")])])],1)])])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }