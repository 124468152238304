<template>
  <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
    <!-- <section class="ds s-py-90 s-py-xl-150 c-gutter-60"> -->
    <div class="container">
      <div class="row">
        <main class="col-lg-12">
          <article>
            <!-- <header class="entry-header">
              <h1 class="entry-title">{{ getCurrentCategory.subtitle }}</h1>
            </header>-->
            <!-- .entry-header -->
            <div class="entry-content">
              <div class="woocommerce">
                <div class="woocommerce-MyAccount-content">
                  <form id="child-form" role="form" style="display: block">
                    <p class="mm-font __mm">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>
                    <label class="form-group">
                      <span class="mm-font __mm">အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        v-model="phone.name"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးသက္ကရာဇ်</span>
                      <!-- <input
                        type="date"
                        class="form-control"
                        name="birth_date"
                        v-model="phone.birth_date"
                        required
                      /> -->

                      <md-datepicker
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        v-model="phone.birth_date"
                      >
                      </md-datepicker>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">နေ့နံ</span>
                      <select
                        class="mm-font __mm"
                        name="nyih_nan"
                        v-model="phone.nyih_nan"
                        required
                      >
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>

                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                    </label>
                    <input
                      type="radio"
                      name="marital_status"
                      v-model="phone.marital_status"
                      value="Yes"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်ရှိ</span>
                    <input
                      type="radio"
                      name="marital_status"
                      v-model="phone.marital_status"
                      value="No"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်မရှိ</span>
                    <br />
                    <br />
                    <!-- <label class="form-group">
                      <span for class="mm-font __mm">နေထိုင်သည့်မြို့</span>
                      <input
                        type="text"
                        class="form-control"
                        name="address"
                        v-model="phone.address"
                        required
                      />
                    </label> -->
                    <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          v-model="phone.address_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <select
                          v-model="phone.address_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/ လမ်း အသေးစိတ်"
                        v-model="phone.address"
                        required
                      />
                    </label>
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      v-model="phone.gender"
                      required
                    />
                    <span for class="mm-font __mm">ကျား</span>
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      v-model="phone.gender"
                      required
                    />
                    <span for class="mm-font __mm">မ</span>
                    <br />
                    <br />
                    <br />
                    <br />
                    <label class="form-group">
                      <span for class="mm-font __mm"
                        >ဆက်သွယ်ရမည့်ဖုန်းနံပါတ်</span
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="phone_number"
                        v-model="phone.phone_number"
                        @input="onlyDigit($event)"
                        placeholder="09xxxxxxxxx"
                        required
                      />
                    </label>

                    <br />
                    <br />

                    <!-- <label class="form-group">
                      <h6 class="mm-font __mm" style="padding: 0; margin: 0">
                        ဗေဒင်ဆရာ/မ များမှ ဖုန်းခေါ်ဆို ပေးစေချင်သောအချိန်
                      </h6>
                      <p class="mm-font __mm" style="padding: 0; margin: 0">
                        တနင်္လာ မှ စနေထိ၊မနက် ၉နာရီ မှ ညနေ ၅နာရီအတွင်းသာ
                      </p>
                      <input
                        type="text"
                        class="form-control mm-font"
                        name="call_time"
                        v-model="phone.call_time"
                      />
                    </label> -->

                    <div class="form-group">
                      <h6 class="mm-font __mm" style="padding: 0; margin: 0">
                        ဗေဒင်ဆရာ/မ များမှ ဖုန်းခေါ်ဆို ပေးစေချင်သောအချိန်
                      </h6>
                      <p class="mm-font __mm" style="padding: 0; margin: 0">
                        တနင်္လာ မှ စနေထိ၊မနက် ၉နာရီ မှ ညနေ ၅နာရီအတွင်းသာ
                      </p>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          id="call-time-day"
                          class="mm-font __mm"
                          v-model="call_time_day"
                          required
                        >
                          <option value="" hidden selected>နေ့</option>
                          <option value="တနင်္လာ">တနင်္လာ</option>
                          <option value="အင်္ဂါ">အင်္ဂါ</option>
                          <option value="ဗုဒ္ဓဟူး">ဗုဒ္ဓဟူး</option>
                          <option value="ကြာသပတေး">ကြာသပတေး</option>
                          <option value="သောကြာ">သောကြာ</option>
                          <option value="စနေ">စနေ</option>
                        </select>
                        <select
                          id="call-time-time"
                          class="mm-font __mm"
                          v-model="call_time_time"
                          required
                        >
                          <option value="" hidden selected>အချိန်</option>
                          <option value="9-12">
                            နံနက် ၉ နာရီမှ ၁၂ နာရီအတွင်း
                          </option>
                          <option value="12-5">
                            ညနေ ၁၂ နာရီမှ ၅ နာရီအတွင်း
                          </option>
                        </select>
                      </div>
                    </div>

                    <br />
                    <br />
                    <p class="mm-font" id="call_service"></p>
                    <p class="mm-font __mm">
                      မေးမြန်းလိုသည့်အကြာင်းအရာကိုရေးပါ။
                    </p>
                    <label class="form-group">
                      <textarea
                        name="about"
                        v-model="phone.about"
                        class="mm-font test"
                        rows="10"
                        cols="35"
                        placeholder
                        style="color: black"
                        required
                      ></textarea>
                    </label>

                    <!-- <label class="form-group">
                      <span class="mm-font __mm">ဗေဒင်ဆရာ</span>
                      <select
                        name="baydin_sayar"
                        v-model="phone.baydin_sayar"
                        class="mm-font __mm"
                        required
                      >
                        <option
                          value=" "
                          class=" mm-font __mm"
                        >ဗေဒင်ဆရာ ရွေးချယ်ပါ...</option>
                        <option
                          value="ဆရာမ သန့်ထားဆု"
                          class="mm-font __mm"
                        >ဆရာမ သန့်ထားဆု</option>
                        <option
                          value="ဆရာ ကျော်ဇောဟန်"
                          class="__mm"
                        >ဆရာ ကျော်ဇောဟန်</option>
                        <option
                          value="ဆရာ မင်းသိမ်းခိုင်"
                          class="__mm"
                        >ဆရာ မင်းသိမ်းခိုင်</option>
                        <option
                          value="ဆရာမ စောလှနွယ်နီ"
                          class="__mm"
                        >ဆရာမ စောလှနွယ်နီ</option>
                        <option
                          value="ဆရာ မိုးမင်းသုခ"
                          class="__mm"
                        >ဆရာ မိုးမင်းသုခ</option>
                        <option
                          value="ဆရာမ စောယုထွေး"
                          class="__mm"
                        >ဆရာမ စောယုထွေး</option>                        
                        <option
                          value="ဆရာမ ဝင့်ယမုံဦး"
                          class="__mm"
                        >ဆရာမ ဝင့်ယမုံဦး</option>
                      </select>
                    </label> -->

                    <!-- <p class="mm-font"></p>
                    <h2 class="text-center __mm">ဗေဒင်ဆရာများ</h2>
                    <p></p>

                    <div class="feature">
                      <figure
                        class="featured-item image-holder r-3-2 transition"
                        style="background-image: url(&quot;https://s3.ap-southeast-1.amazonaws.com/assets.myclip.com/smart/HquaoE4zf0CpyGciDKk7m2JWzYR1Y9Rh0SXu3y2f.jpeg&quot;);"
                      ></figure>
                    </div>

                    <div class="gallery-wrapper">
                      <div class="gallery">
                        <div class="item-wrapper">
                          <figure
                            class="gallery-item image-holder r-3-2 active transition"
                            style="background-image: url(&quot;https://s3.ap-southeast-1.amazonaws.com/assets.myclip.com/smart/HquaoE4zf0CpyGciDKk7m2JWzYR1Y9Rh0SXu3y2f.jpeg&quot;);"
                          ></figure>
                        </div>
                        <div class="item-wrapper">
                          <figure
                            class="gallery-item image-holder r-3-2 transition"
                            style="background-image: url(&quot;https://s3.ap-southeast-1.amazonaws.com/assets.myclip.com/smart/QwGFOX5UMFViMPGMqLO01ctjd7TPZXSUxiud0fOi.jpeg&quot;);"
                          ></figure>
                        </div>
                        <div class="item-wrapper">
                          <figure
                            class="gallery-item image-holder r-3-2 transition"
                            style="background-image: url(&quot;https://s3.ap-southeast-1.amazonaws.com/assets.myclip.com/smart/jtJJrzwGwJK4hEKCEULxWS2JhmbW8X6JHSAj9mdf.jpeg&quot;);"
                          ></figure>
                        </div>
                        <div class="item-wrapper">
                          <figure
                            class="gallery-item image-holder r-3-2 transition"
                            style="background-image: url(&quot;https://s3.ap-southeast-1.amazonaws.com/assets.myclip.com/smart/1dfvLNMQn0eO9KA6sYlrmT7mMb2lBIGKzJUmNBGq.jpeg&quot;);"
                          ></figure>
                        </div>
                        <div class="item-wrapper">
                          <figure
                            class="gallery-item image-holder r-3-2"
                            style="background-image: url(&quot;https://s3.ap-southeast-1.amazonaws.com/assets.myclip.com/smart/bqT3PnK3tJ7Xn1JWylN2ZEUnrQkNWKlfKh3bV0bC.jpeg&quot;);"
                          ></figure>
                        </div>
                        <div class="item-wrapper">
                          <figure
                            class="gallery-item image-holder r-3-2 transition"
                            style="background-image: url(&quot;https://s3.ap-southeast-1.amazonaws.com/assets.myclip.com/smart/g1UFtmxAzcMJeRxyaUM8V0VRzhQUAQ6KzfJHWQaG.jpeg&quot;);"
                          ></figure>
                        </div>
                        <div class="item-wrapper">
                          <figure
                            class="gallery-item image-holder r-3-2 transition"
                            style="background-image: url(&quot;https://s3.ap-southeast-1.amazonaws.com/assets.myclip.com/smart/HPZTllBvWKhA2tvfJMbd4zhZHv3cOzTi0L6oji9n.jpeg&quot;);"
                          ></figure>
                        </div>
                        <div class="item-wrapper">
                          <figure
                            class="gallery-item image-holder r-3-2 transition"
                            style="background-image: url(&quot;https://s3.ap-southeast-1.amazonaws.com/assets.myclip.com/smart/d9qxmZ8QaUtamRxc57pWAtRRGckKYKtvRRuZkMZv.jpeg&quot;);"
                          ></figure>
                        </div>
                        <div class="item-wrapper">
                          <figure
                            class="gallery-item image-holder r-3-2 transition"
                            style="background-image: url(&quot;https://s3.ap-southeast-1.amazonaws.com/assets.myclip.com/smart/BaZhNOTgtAzi6SVdg25WUqigHNShv75XWDuLU5TP.jpeg&quot;);"
                          ></figure>
                        </div>
                        <div class="item-wrapper">
                          <figure
                            class="gallery-item image-holder r-3-2 transition"
                            style="background-image: url(&quot;undefined&quot;);"
                          ></figure>
                        </div>
                      </div>
                    </div>

                    <div class="controls">
                      <a class="move-btn left" style="font-size: 30px">←</a>
                      <a class="move-btn right" style="font-size: 30px">→</a>
                    </div> -->
                    <!-- <label
                      class="form-group"
                      v-if="
                        actionBtnMethod == 'mpitesan' &&
                        this.categoryName == 'chiromancy'
                      "
                    >
                      <span for class="mm-font __mm">Promotion Code</span>
                      <input
                        type="text"
                        class="form-control"
                        name="promocode"
                        v-model="promocode"
                      />
                    </label> -->
                    <p>
                      <input
                        type="submit"
                        @click.prevent="paymentProvider()"
                        :disabled="disabled"
                        class="woocommerce-Button button mm-font __mm"
                        name="save_account_details"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <!-- .entry-content -->
          </article>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { Horo, HTTP } from "@core/lib/http-common";
import { getFormData, checkFormModel } from "@core/lib/form-data";
import { makePayment, onePay, citizenPay } from "../js/payment";
import { getDivisions, getTownships } from "../js/addressInfo";
import moment from "moment";

export default {
  name: `Special`,
  data() {
    return {
      actionBtnMethod: "",
      disabled: false,
      onepayPhoneNumber: "",
      divisions: [],
      townships: [],
      call_time_day: "",
      call_time_time: "",
      // promocode: "",
      phone: {
        name: "",
        birth_date: moment(new Date("01/01/1930")).format("YYYY-MM-DD"),
        nyih_nan: "",
        address: "",
        address_division: "",
        address_township: "",
        phone_number: "",
        order_id: "0",
        gender: "",
        call_time: "",
        user_id: "0",
        about: "",
        baydin_sayar: " ",
        marital_status: "",
        price: "5000",
      },
    };
  },
  async created() {
    this.divisions = await getDivisions();
    this.actionBtnMethod = this.$store.state.paymentName;
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";
  },
  computed: {
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),
  },
  watch: {
    call_time_day: function (value) {
      this.phone.call_time = value + " " + this.call_time_time;
    },
    call_time_time: function (value) {
      this.phone.call_time = this.call_time_day + " " + value;
    },
  },

  methods: {
    async handleDivisionChange(e) {
      let nrc_id = e.target.options[e.target.selectedIndex].dataset.nrc;
      this.townships = await getTownships(nrc_id);
    },

    onlyDigit($event) {
      let key = $event.keyCode || $event.charCode;
      if ($event.target.value.length > 11 && key != 8) {
        $event.target.value = $event.target.value.substr(
          0,
          $event.target.value.length - 1
        );
      }

      $event.target.value = $event.target.value.replace(/[^\d]/gi, "");
    },

    paymentProvider() {
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        case "citizenpay":
          this.citizenPayProvicer();
          break;
        case "bppay":
          this.bpPay();
          break;
        default:
          this.precreate();
          break;
      }
    },

    async citizenPayProvicer() {
      if (checkFormModel(this.phone)) {
        this.disabled = true;

        let formdata = getFormData(this.phone);
        formdata.append("user_phone", this.phone.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 44444,
          amount: this.phone.price,
          category_name: "phone",
        };
        await citizenPay([
          "1875/callService/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.phone.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async onePayProvider() {
      if (checkFormModel(this.phone)) {
        this.disabled = true;

        let formdata = getFormData(this.phone);
        formdata.append("user_phone", this.phone.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 44444,
          amount: this.phone.price,
          category_name: "phone",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.direct.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/callService/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.phone.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async bpPay() {
      if (checkFormModel(this.phone)) {
        this.disabled = true;

        let formdata = getFormData(this.phone);
        formdata.append("user_phone", this.phone.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 44444,
          amount: this.phone.price,
          category_name: "phone",
          // promocode: this.promocode,
          phone_number: this.phone.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/callService/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.phone.price,
        ], {
          coupon_code : this.$store.state.discount_coupon
        })
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //     name: "paymentPortal",
              // });
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortalV3",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "BPPay" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      if (checkFormModel(this.phone)) {
        this.disabled = true;

        let formdata = getFormData(this.phone);
        formdata.append("user_phone", this.phone.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 44444,
          amount: this.phone.price,
          category_name: "phone",
          // promocode: this.promocode,
          phone_number: this.phone.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/callService/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.phone.price,
        ], {
          coupon_code : this.$store.state.discount_coupon
        })
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //     name: "paymentPortal",
              // });
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortal",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
    precreate() {
      if (checkFormModel(this.phone)) {
        this.disabled = true;
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: 44444,
          amount: this.phone.price,
          category_name: "phone",
        })
          .then((response) => {
            const res = response.data;
            this.phone.order_id = res.order_id;
            this.phone.user_id = res.user_id;
            let formdata = getFormData(this.phone);
            formdata.set("price", res.amount);
            formdata.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            Horo.post("1875/callService/horoscope", formdata).then(
              (response) => {
                this.res_status = response.data.status;
                this.kbzpay.startPay(
                  res.prepay_id,
                  res.order_info,
                  res.sign_app,
                  ({ resultCode, lang }) => {
                    if (resultCode == 1) {
                      if (lang == "en") {
                        this.$swal(
                          "Success",
                          "Your Purchase Success",
                          "success"
                        );
                        this.$router.push({ name: "home" });
                      }
                      if (lang == "my") {
                        this.$swal(
                          "အောင်မြင်ပါသည်",
                          "ဝယ်ယူပြီးပါပြီ",
                          "success"
                        );
                        this.$router.push({ name: "home" });
                      }
                    }
                    if (resultCode == 2) {
                      if (lang == "en") {
                        this.$swal("Failed", "Your Purchase Failed", "error");
                        this.disabled = false;
                      }
                      if (lang == "my") {
                        this.$swal(
                          "မအောင်မြင်ပါ",
                          "ထပ်မံကြိုးစားပေးပါ",
                          "error"
                        );
                        this.disabled = false;
                      }
                    }
                  },
                  () => {
                    this.$swal("Failed", "Your Purchase Failed", "error");
                    this.disabled = false;
                  }
                );
              }
            );
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  display: inline;
  margin-top: 8px;
  margin-left: 3px;
}

.input-container {
  display: flex;
  border: 1px solid #000;
  background-color: #f2eff7;
  color: #3e1e68;
  border-radius: 25px;
  padding: 8px 0px 0px 10px;
}
.md-field.md-theme-default:after {
  background-color: rgba(0, 0, 0, 0);
}
.md-button.md-theme-default {
  margin: 0px 16px 0px 0px;
}
</style>
